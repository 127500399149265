<template>
  <body>
    <div>
    <v-app-bar
      color="deep-purple accent-4"
      dense
      dark
    >
      <v-icon>mdi-cat</v-icon>
      <router-link to="/">
      <v-toolbar-title style="font-family: TheJamsil5Bold;">돈맛</v-toolbar-title></router-link>
  
     <v-spacer></v-spacer>
  
     <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          > 
          <router-link to="/setting">
            <v-icon>mdi-cog</v-icon></router-link>
          <div class="accoun">
          <router-link to="/account" v-if="!fnGetAuthStatus" >
            <v-icon>mdi-account</v-icon></router-link>
            <!-- 로그인 된 경우만 로그아웃 버튼을 표시함 -->
           
          <v-list-item @click="fnDoLogout" v-if="fnGetAuthStatus">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-title>로그아웃</v-list-item-title>
          </v-list-item> 
        
             </div>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
  </div>
  <v-main>
    <router-view></router-view>
  </v-main>
  </body>
  </template>
  
  <script>
  import 'firebase/compat/database'
  import { osinDB } from '@/datasources/firebase';

  export default({
    data() {
    return {
      modalCheck: false,
      searchID: "",
      searchList: [{name:"찾기",value:"찾기"}],
      childKey: null
    }
  },
  methods: {
    async fnSetSearchList() {
      if(this.searchID){
      const searchDB = osinDB.ref('users/' + this.searchID)
      const snapshot = await searchDB.once("value");
      const restaurants = snapshot.val()
      let count = 0;
      if (restaurants) {
        this.searchList = []
        for (let childName of restaurants){
          for (let restauran of restaurants){
            if(restauran.childName === childName.childName){
              break
            }
            count++;
          }
          this.searchList.push({name:childName.childName, value:count})
          count = 0
        }
      } else {
        this.searchList = [{name:"없음",value:"없음"}]
      }
    }
    },
    async fnSetMenuList(){
      if(this.searchID){
        const updatedChild = prompt("저장할 이름을 입력하세요");
        if(updatedChild){
          const searchDB = osinDB.ref('users/' + this.searchID).child(this.childKey).child("child")
          const osinDBRef = osinDB.ref('users/' + this.fnGetUserID)
          const snapshot_search = await searchDB.once("value");
          const restaurants_search = snapshot_search.val()
          const snapshot_my = await osinDBRef.once("value");
          const restaurants_my = snapshot_my.val()
          const childNameCheck = restaurants_my.find((item)=>item.childName === updatedChild)
          if (!childNameCheck){
            osinDBRef.child(restaurants_my.length).set({
              "child": restaurants_search,
              "childName":updatedChild
            })
          }
        }
      }
      this.modalOpen()
    }
    ,
    ex(){
      console.log(childKey)
    },
    modalOpen() {
      this.modalCheck = !this.modalCheck
    },
    fnChoiceMenus () {
          this.$store.dispatch('fnSettingMenus');
        },
        fnDoLogout() {
          this.$store.commit("fnSetChild", "광운대")
      this.$store.dispatch('fnDoLogout')
    }
  },
  computed: {
    fnGetUserID(){
      if(this.$store.getters.fnGetUser){
      return this.$store.getters.fnGetUser.id
      } else {
        return "defaultUserID"
      }
    },
        // 스토어에서 현재 인증 상태인지 반환
        fnGetAuthStatus() {
          return this.$store.getters.fnGetAuthStatus
        }
      },
      fnGetMenuItems() {
          if (!this.fnGetAuthStatus) {
            return [{
              title: '회원가입',
              to: '/register',
              icon: 'mdi-lock-open-outline'
            }]
          } else {
            return [{
              title: '메인 페이지',
              to: '/',
              icon: 'mdi-account'
            }]
          }
        }
  })
  
  </script>
  
  <style>
  .foot {
    position: fixed; /* 이 부분을 고정 */
    bottom: 0; /* 하단에 여백 없이 */
    width: 100%;
  } /*네비게이션*/
  
  .foot-icon {
    color: black;
  }
  a{text-decoration:none; color: white}
  
  .foot-icon{text-decoration:none; color: black}
  .modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  /* modal or popup */
  .modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  .modal-btn {
    box-sizing:border-box;
    font-size: 17px;
  }
  .accoun{
    margin-right: 80px;
    white-space: nowrap;
  }
  .putt{
  outline: 2px solid rgb(87, 87, 87);
  width: 150px;
  height: 24px;
  align-items: center;
  border-radius: 3px;
}
@media (max-width: 600px) {
  .accoun {
    margin-right: 20px;
  }
}
  </style>
  
  
  
  
  
  