<template>
  <body>
    <div style="text-align:center; font-family: TheJamsil5Bold; margin-top: 70px;">지역 :
    <select v-model="result" @change="ChangeBox" @click="fnSetChildList" style="text-align:center; width:100px; height:30px;">
    <option v-for="(place, index) in childList" 
       :value="place.value" 
       v-bind:key="index">
      {{place.name}}
      </option>
    </select>

     <div class="abou">
      <v-checkbox @click="fnSetCheckbox" v-model="menuChecked[0]" :label="menu1"></v-checkbox>
      <v-checkbox @click="fnSetCheckbox" v-model="menuChecked[1]" :label="menu2"></v-checkbox>
      <v-checkbox @click="fnSetCheckbox" v-model="menuChecked[2]" :label="menu3"></v-checkbox>
      <br><br><br>                                                      
      <span><strong>TOTAL : {{cost}}</strong></span>
      <br>
      <span><strong>현재 식비 : {{fnGetBudget}}</strong></span>
    </div>
    </div>
    <footer class="foot">
    <v-bottom-navigation class="foot"
    grow
    absolute
  >
  
    <v-btn>
      <v-icon style="color: black;" class="foot-icon" @click="modalOpen">mdi-magnify</v-icon>
      <div class="modal-wrap" v-show="modalCheck">
    <div class="modal-container">
      <p style="font-family: TheJamsil5Bold; color: rgb(87, 87, 87);" >ID : {{ fnGetUserID }}</p>
      <br>
      <label style="font-family: TheJamsil5Bold; color: rgb(87, 87, 87);" for="searchID">찾을 ID : </label>
      <input class="putt" type="text" id="searchID" v-model="searchID" />
      <div style="text-align:center; font-family: TheJamsil5Bold; margin-top: 30px;">지역:
        <select v-model="childKey" @change="fnSetMenuList" @click="fnSetSearchList" style="text-align:center; width:100px; height:30px;">
        <option v-for="(place, index) in searchList" 
           :value="place.value" 
         v-bind:key="index">
         {{place.name}}
         </option>
        </select>
        </div>
      ...  <!--  모달창 content  -->
      
      <div class="modal-btn">
        <button @click="modalOpen">닫기</button>
      
      </div>
    </div>
  </div>
    </v-btn>
    
    <v-btn @click="fnChoiceMenus">
      <v-icon style="color: black;">mdi-paw</v-icon>      
    </v-btn>
  
    <v-btn>
      <router-link to="/ex" class="foot-icon">
      <v-icon>add</v-icon></router-link>
    </v-btn>
    </v-bottom-navigation>
  </footer>
</body>
</template>

<script>
import 'firebase/compat/database'
import { osinDB } from '@/datasources/firebase';

  export default {
    name: 'Home',
    
    data() {
      return {
        searchID: "",
        money: 0,
        menuChecked: [false, false, false],
        result:this.$store.getters.fnGetChild,
        childList: [
        {name: this.$store.getters.fnGetChild, value: this.$store.getters.fnGetChild},
      ],
      modalCheck: false,
      searchID: "",
      searchList: [{name:"찾기",value:"찾기"}],
      childKey: null
      }
    },

    computed: {
      fnGetUserID(){
      if(this.$store.getters.fnGetUser){
      return this.$store.getters.fnGetUser.id
      } else {
        return "defaultUserID"
      }
    },
        // 스토어에서 현재 인증 상태인지 반환
        fnGetAuthStatus() {
          return this.$store.getters.fnGetAuthStatus
        },
      fnGetMenuItems() {
          if (!this.fnGetAuthStatus) {
            return [{
              title: '회원가입',
              to: '/register',
              icon: 'mdi-lock-open-outline'
            }]
          } else {
            return [{
              title: '메인 페이지',
              to: '/main',
              icon: 'mdi-account'
            }]
          }
        },
      fnGetChild() {
      return this.$store.getters.fnGetChild
    },
      fnGetAuthStatus() {
      return this.$store.getters.fnGetAuthStatus
    },
      userID() {
      if (this.fnGetAuthStatus) {
        return this.$store.getters.fnGetUser.id
      } else {
        return "defaultUserID"
      }
    },
      selectedMenus() {
        return this.$store.getters.fnGetMenus;
      },
      osinDBRef() {
      return osinDB.ref('users/' + this.userID);
    },

     menu1() {
        if (this.selectedMenus && this.selectedMenus[0]) {
         return this.selectedMenus[0].restaurantName+" : "+this.selectedMenus[0].menuName+" -> "+this.selectedMenus[0].price;
       }
       return '아침 : 메뉴 -> 가격';
      },
      menu2() {
       if (this.selectedMenus && this.selectedMenus[1]) {
         return this.selectedMenus[1].restaurantName+" : "+this.selectedMenus[1].menuName+" -> "+this.selectedMenus[1].price;
       }
       return '점심 : 메뉴 -> 가격';
      },
      menu3() {
       if (this.selectedMenus && this.selectedMenus[2]) {
         return this.selectedMenus[2].restaurantName+" : "+this.selectedMenus[2].menuName+" -> "+this.selectedMenus[2].price;
       }
       return '저녁 : 메뉴 -> 가격';
      },
      cost() {
       if (!this.selectedMenus || this.selectedMenus.length === 0) {
         return 0;
       }
       return this.selectedMenus.reduce((total, menu) => total + menu.price, 0);
      },
      fnGetBudget() {
        return this.$store.getters.fnGetBudget;
      }
    },
    methods: {
      fnChoiceMenus () {
          this.$store.dispatch('fnSettingMenus');
        },
      modalOpen() {
      this.modalCheck = !this.modalCheck
    },
      async fnSetSearchList() {
      if(this.searchID){
      const searchDB = osinDB.ref('users/' + this.searchID)
      const snapshot = await searchDB.once("value");
      const restaurants = snapshot.val()
      let count = 0;
      if (restaurants) {
        this.searchList = []
        for (let childName of restaurants){
          for (let restauran of restaurants){
            if(restauran.childName === childName.childName){
              break
            }
            count++;
          }
          this.searchList.push({name:childName.childName, value:count})
          count = 0
        }
      } else {
        this.searchList = [{name:"없음",value:"없음"}]
      }
    }
    },
    async fnSetMenuList(){
      if(this.searchID){
        const updatedChild = prompt("저장할 이름을 입력하세요");
        if(updatedChild){
          const searchDB = osinDB.ref('users/' + this.searchID).child(this.childKey).child("child")
          const osinDBRef = osinDB.ref('users/' + this.fnGetUserID)
          const snapshot_search = await searchDB.once("value");
          const restaurants_search = snapshot_search.val()
          const snapshot_my = await osinDBRef.once("value");
          const restaurants_my = snapshot_my.val()
          const childNameCheck = restaurants_my.find((item)=>item.childName === updatedChild)
          if (!childNameCheck){
            osinDBRef.child(restaurants_my.length).set({
              "child": restaurants_search,
              "childName":updatedChild
            })
          }
        }
      }
      this.modalOpen()
    },
      fnSetChild() {
      this.$store.commit("fnSetChild",this.result)
    },
      ChangeBox(){ 
      this.fnSetChild()
    },
      async fnSetChildList() {
      const snapshot = await this.osinDBRef.once("value");
      this.childList = []
      const restaurants = snapshot.val()
      this.childList.push({name:this.fnGetChild,value:this.fnGetChild});
      for (let childName of restaurants){
        if(childName.childName === this.fnGetChild){
          continue;
        }
        this.childList.push({name:childName.childName, value:childName.childName})
      }
    },
      fnSetCheckbox () {
        this.$store.commit('fnSetmenuChecked', this.menuChecked);
      }
    }
  }
</script>

<style>
@font-face {
    font-family: 'TheJamsil5Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/TheJamsil5Bold.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
 body {
margin: 0;
}
.abou{
  display: flex; 
  flex-direction: column; 
  align-items: center;
  margin-top: 60px;
  font-family: TheJamsil5Bold;
  white-space: nowrap;
}
.pu {
  outline: 2px solid gray;
  width: 150px;
  height: 24px;
  border-radius: 3px;
}
</style>