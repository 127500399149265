// 파이어베이스 앱 객체 모듈 가져오기
import firebase from 'firebase/compat/app'
// 파이어베이스 패키지 모듈 가져오기
import 'firebase/compat/auth'
import 'firebase/compat/database'
// Firebase 초기화
firebase.initializeApp({
    apiKey: "AIzaSyBt3mHMFp5KRcqgtnsiGGnAf2Rc9XCfZwA",
    authDomain: "test-project-8b254.firebaseapp.com",
    databaseURL: "https://test-project-8b254-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "test-project-8b254",
    storageBucket: "test-project-8b254.appspot.com",
    messagingSenderId: "48194120830",
    appId: "1:48194120830:web:8c95f874afdd688fb77c2f"
});

// Realtime Database 참조
export const osinDB = firebase.database()
export const oFirebaseAuth = firebase.auth();